import { WizardForm } from "../../Components/WizardForm/WizardForm";
import { CommitmentStep } from "./Steps/CommitmentStep";
import { ConfigurationStep } from "./Steps/ConfigurationStep";
import { GeneralStep } from "./Steps/GeneralStep";
import { validationSchema } from "./utils";

export const CreateCommitmentFlow = () => {
  const initialValues = {
    selectedOption: "",
    planName: "",
    selectedContracts: [],
    currency: "USD",
    totalValue: "",
    startDate: new Date(),
    numberOfPeriods: 1,
    commitments: [],
    excess: false,
    excessValue: "",
    shortfall: false,
    shortfallValue: "",
    accountFilter: { exclude: false, key: "", values: [] },
    serviceFilter: { exclude: false, key: "", values: [] },
    skuFilter: { exclude: false, key: "", values: [] },
  };

  const steps = [GeneralStep, CommitmentStep, ConfigurationStep];

  return (
    <WizardForm initialValues={initialValues} steps={steps} onSubmit={() => {}} validationSchema={validationSchema} />
  );
};
