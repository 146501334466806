import { NavLink } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

import { homeBullets } from "./consts";

export const OnboardingCM = () => (
  <Container>
    <Typography variant="h1">Commitment manager</Typography>
    <Typography color="text.secondary">
      Compare your current cloud spend to your planned spend, all tracked along the baseline of your contracted commit
      spend.
    </Typography>
    {homeBullets.map(({ bold, text }, index) => (
      <Typography
        key={index}
        variant="body1"
        color="text.secondary"
        sx={{
          display: "list-item",
          listStyleType: "disc",
          marginLeft: 2,
          listStylePosition: "inside",
        }}
      >
        <Typography fontWeight={700} sx={{ display: "inline", mr: 1 }}>
          {bold}
        </Typography>
        {text}
      </Typography>
    ))}

    <Button component={NavLink} variant="contained" to="./commitment-manager/create">
      Create commitment plan
    </Button>
  </Container>
);
