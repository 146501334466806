import useTransforms from "../../Components/hooks/cloudAnalytics/useTransforms";

export const useGetLabelText = (filter) => {
  const [transforms] = useTransforms();

  const getLabelText = (filterValue) => {
    const labelAux = transforms?.[filter.id]?.(filterValue);
    const labelText = labelAux ?? filterValue;
    if (labelText.length > 20) {
      return `${labelText.slice(0, 20)}...`;
    }
    return labelText;
  };

  return getLabelText;
};
