import { Currencies, CurrencyCodes } from "@doitintl/cmp-models";
import { Autocomplete } from "@mui/material";

import { getCurrencyLabelByCode } from "../../../../utils/common";
import { CustomInput } from "../common/CustomInput";

const isValidCurrency = (currency, country) => !country || country === "Israel" || currency !== CurrencyCodes.ILS;

type Props = {
  onChange: (currency: string) => void;
  value: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  size?: "small" | "medium";
};

export const AutocompleteCurrency = ({
  value,
  onChange,
  disabled,
  error,
  label = "Billing currency",
  size = "medium",
}: Props) => {
  const validCurrencies = Currencies.filter((currency) => isValidCurrency(currency.value, value));
  const options = validCurrencies.map((currency) => currency.value);
  return (
    <Autocomplete
      data-cy="currency"
      disabled={disabled}
      disableClearable
      options={options}
      value={value}
      onChange={(_, value) => {
        if (value) {
          onChange(value);
        }
      }}
      getOptionLabel={getCurrencyLabelByCode}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {getCurrencyLabelByCode(option)}
        </li>
      )}
      renderInput={(params) => (
        <CustomInput name="currency" label={label} required error={Boolean(error)} {...params} size={size} />
      )}
    />
  );
};
