import { useParams } from "react-router";
import { Container, Stack, Step, StepLabel, Stepper } from "@mui/material";

import { ButtonsFooter } from "../../Components/ButtonsFooter/ButtonsFooter";
import { useMultistep } from "./hooks";
import { CONFIGURATION_STEP, CONNECTION_STEP } from "./Steps/consts";
import { type ClusterConfig, type OnboardingStep, onboardingStepLabels } from "./types";
import { getCurrentStepIndex } from "./utils";

type Props = {
  clusters: ClusterConfig[];
};

export const Onboarding = ({ clusters }: Props) => {
  // get param from url
  const { clusterId } = useParams<{ clusterId: string }>();

  const clusterConfig = clusters.find((cluster) => cluster.id === clusterId);
  const { currentStep, step, steps, back, next, allowNext } = useMultistep(clusterConfig!);

  const stepLabels = Object.keys(steps).map((key) => onboardingStepLabels[key as OnboardingStep]);

  return (
    <Stack sx={{ pt: 4 }}>
      {clusterConfig?.status !== "pending-update" && (
        <Stepper activeStep={getCurrentStepIndex(currentStep)} alternativeLabel>
          {stepLabels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Container maxWidth="md" sx={{ mt: 4 }}>
        {step}
      </Container>
      <ButtonsFooter
        isLastStep={currentStep === CONNECTION_STEP || currentStep === CONFIGURATION_STEP}
        onBackClicked={back}
        onNextClicked={next}
        allowNext={allowNext}
      />
    </Stack>
  );
};
