import { useEffect } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormikContext } from "formik";

import { type Contract } from "../../../types";
import { CommitmentTable } from "../CommitmentTable";
import { CustomCheckbox } from "../CustomCheckbox";

interface CommitmentStepValues {
  totalValue: string;
  startDate: Date;
  numberOfPeriods: number;
  excess: boolean;
  excessValue: number;
  shortfall: boolean;
  shortfallValue: boolean;
  trueUp: boolean;
  selectedContracts: Contract[];
}

export const CommitmentStep = () => {
  const { values, handleChange, errors, touched } = useFormikContext<CommitmentStepValues>();

  useEffect(() => {
    const commitments = values.selectedContracts?.[0]?.commitmentPeriods;
    if (values.selectedContracts.length === 1 && commitments && commitments?.length > 0) {
      handleChange({ target: { name: "numberOfPeriods", value: commitments.length } });
      handleChange({ target: { name: "startDate", value: commitments[0].startDate.toDate() } });
      let totalCommitment = 0;
      commitments.forEach((commitment, index) => {
        handleChange({ target: { name: `commitments[${index}].commitmentValue`, value: commitment.value } });
        handleChange({ target: { name: `commitments[${index}].endDate`, value: commitment.endDate.toDate() } });
        handleChange({ target: { name: `commitments[${index}].startDate`, value: commitment.startDate.toDate() } });
        const diffTime = Math.abs(commitment.endDate.toDate().getTime() - commitment.startDate.toDate().getTime());
        const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
        handleChange({ target: { name: `commitments[${index}].periodLength`, value: diffMonths } });
        totalCommitment += commitment.value;
      });
      handleChange({ target: { name: "totalValue", value: totalCommitment } });
    }
  }, [values.selectedContracts, handleChange]);

  return (
    <Stack spacing={3}>
      <Typography variant="h4" fontWeight={500}>
        Commitment information
      </Typography>
      <TextField
        label="Total commitment value"
        autoFocus
        required
        variant="outlined"
        fullWidth
        name="totalValue"
        value={values.totalValue}
        onChange={handleChange}
        error={!!errors.totalValue && touched.totalValue}
        helperText={touched.totalValue && errors.totalValue}
      />
      <Stack direction="row" spacing={1.5}>
        <DatePicker
          disableMaskedInput
          label="Start date"
          value={values.startDate}
          inputFormat="dd MMMM yyyy"
          onChange={(e) => {
            handleChange({ target: { name: "startDate", value: e } });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={touched.startDate && Boolean(errors.startDate)}
              fullWidth
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />

        <FormControl
          variant="outlined"
          fullWidth
          required
          error={touched.numberOfPeriods && Boolean(errors.numberOfPeriods)}
        >
          <InputLabel>Number of periods</InputLabel>
          <Select
            label="Number of periods"
            name="numberOfPeriods"
            value={values.numberOfPeriods}
            onChange={handleChange}
            size="small"
          >
            {[...Array(5)].map((_, i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
          {touched.numberOfPeriods && errors.numberOfPeriods && (
            <FormHelperText>{errors.numberOfPeriods}</FormHelperText>
          )}
        </FormControl>
      </Stack>
      <CommitmentTable numberOfPeriods={values.numberOfPeriods} startDate={values.startDate} />
      <Stack pt={2}>
        <Typography variant="subtitle1" fontWeight={500} pb={1.5}>
          Commitment rollover
        </Typography>
        <Typography variant="subtitle2" fontWeight={500}>
          Excess
        </Typography>
        <CustomCheckbox
          checked={values.excess}
          name="excess"
          handleChange={handleChange}
          title="Yes, this plan has excess rollover"
          subtitle="Any excess will be rolled over to the next period"
          value={values.excessValue}
          errors={errors}
          touched={touched}
          required
        />

        <Typography variant="subtitle2" fontWeight={500} pt={2}>
          Shortfall
        </Typography>
        <CustomCheckbox
          checked={values.shortfall}
          name="shortfall"
          handleChange={handleChange}
          title="Yes, this plan has shortfall rollover"
          subtitle="Fill in the percentage of the shortfall that will be rolled over to the next period"
          value={values.shortfallValue}
          errors={errors}
          touched={touched}
          required
        />

        <Typography variant="subtitle1" fontWeight={500} pb={1.5} pt={3}>
          True up invoices
        </Typography>
        <FormControlLabel
          sx={{ pl: 1 }}
          control={<Checkbox checked={values.trueUp} onChange={handleChange} name="trueup" color="primary" />}
          label="Include true up invoices in the commitment"
        />
      </Stack>
    </Stack>
  );
};
