import { useEffect } from "react";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { InfoTooltip } from "../../Components/InfoTooltip/InfoTooltip";
import { CustomTextField } from "./CustomTextField";
import { HeaderCell } from "./HeaderCell";
import { type Commitment } from "./types";
import { addMonths, formatDate } from "./utils";

interface CommitmentValues {
  commitments: Commitment[];
}

type Props = {
  numberOfPeriods: number;
  startDate: Date;
};

export const CommitmentTable = ({ numberOfPeriods }: Props) => {
  const { values, handleChange, errors, touched } = useFormikContext<CommitmentValues>();

  useEffect(() => {
    if (numberOfPeriods < 0) {
      return;
    }
    const prevNum = values.commitments.length;
    if (prevNum < numberOfPeriods) {
      const currentDate = prevNum === 0 ? new Date() : values.commitments[prevNum - 1].endDate;
      const newCommitment: Commitment = {
        startDate: currentDate,
        endDate: addMonths(currentDate, 12),
        periodLength: 12,
        commitmentValue: "",
      };
      const newCommitments = [...values.commitments, newCommitment];
      handleChange({ target: { name: "commitments", value: newCommitments } });
    }
    if (prevNum > numberOfPeriods) {
      const newCommitments = values.commitments.slice(0, numberOfPeriods);
      handleChange({ target: { name: "commitments", value: newCommitments } });
    }
  }, [numberOfPeriods, handleChange, values.commitments]);

  if (numberOfPeriods === 0) {
    return null;
  }

  const handleChangePeriod = (index: number, value: number) => {
    const newCommitments = values.commitments.map((commitment, i) => {
      if (i === index) {
        return {
          ...commitment,
          periodLength: value,
          endDate: addMonths(commitment.startDate, value),
        };
      } else if (i > index) {
        return {
          ...commitment,
          startDate: addMonths(commitment.startDate, value),
          endDate: addMonths(commitment.endDate, value),
        };
      }

      return commitment;
    });
    handleChange({ target: { name: "commitments", value: newCommitments } });
  };

  const handleChangeValue = (index: number, value: string) => {
    const newCommitments = values.commitments.map((commitment, i) => {
      if (i === index) {
        return { ...commitment, commitmentValue: value };
      }
      return commitment;
    });
    handleChange({ target: { name: "commitments", value: newCommitments } });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderCell>Period</HeaderCell>
            <HeaderCell>Start & end date</HeaderCell>
            <HeaderCell>Period length (months)</HeaderCell>
            <TableCell>
              <InfoTooltip description="Maximum percentage of marketplace consumption that contributes to commitment">
                <Typography variant="caption">Commitment value</Typography>
              </InfoTooltip>
            </TableCell>
            <TableCell align="right">
              <Box mr={-2}>
                <InfoTooltip description="Total monetary amount agreed to spend on cloud services per period">
                  <Typography variant="caption">Marketplace spend</Typography>
                </InfoTooltip>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.commitments.map((commitment, i) => (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                {formatDate(commitment.startDate)} - {formatDate(commitment.endDate)}
              </TableCell>
              <TableCell sx={{ width: { lg: 200 }, verticalAlign: "top" }}>
                <CustomTextField
                  value={commitment.periodLength.toString()}
                  onChange={(e) => {
                    handleChangePeriod(i, Number(e.target.value));
                  }}
                  errors={errors.commitments?.[i]}
                  touched={touched.commitments?.[i]}
                  name="periodLength"
                  type="number"
                />
              </TableCell>

              <TableCell sx={{ verticalAlign: "top" }}>
                <CustomTextField
                  value={commitment.commitmentValue}
                  onChange={(e) => {
                    handleChangeValue(i, e.target.value);
                  }}
                  errors={errors.commitments?.[i]}
                  touched={touched.commitments?.[i]}
                  name="commitmentValue"
                />
              </TableCell>
              <TableCell align="right">25%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
