import { type KeyboardEvent, type KeyboardEventHandler, useCallback } from "react";

import {
  AccountManagerCompanies,
  type AccountManagerModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
  CategoryStatus,
  Classification,
  Currencies,
  CurrenciesMap,
  type CurrencyCode,
  CurrencyCodes,
  type CurrencySymbol,
  type DoitRole,
  SaaSConsoleType,
  type UserPermission,
  UserPermissions,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { blue, green, grey, purple, red, yellow } from "@mui/material/colors";
import copy from "copy-to-clipboard";
import sample from "lodash/sample";
import { DateTime, type DateTimeFormatOptions } from "luxon";

import iconAws from "../assets/aws-logo.svg";
import iconAwsDark from "../assets/aws-logo-dark-mode.svg";
import iconDoit from "../assets/doit-logo-hero-square.svg";
import googleCloudIcon from "../assets/google-cloud.png";
import iconAzure from "../assets/microsoft-azure.png";
import { CloudConnectStatusText } from "../assets/texts";
import { asyncConvertCurrencyTo } from "../Context/AsyncCurrencyConverterContext";
import { useCustomerContext } from "../Context/CustomerContext";
import { customersRoute } from "../Navigation/core/utils";
import { type OrganizationWSnap } from "../Pages/IAM/Organizations/Types";
import { type Customer } from "../types";
import { type FirestoreTimestamp, TimestampFromDate } from "./firebase";

export const navigatorSubscriptionURL = "https://www.doit.com/pricing/#navigator";
export const HelpSite = "https://help.doit.com";
export const CSPCustomerID = "CIgtnEximnd4fevT3qIU";

export const TimestampFromDateTime = (t: DateTime) => TimestampFromDate(t.toJSDate());

export const formatStringDate = (date: string) => {
  const dt = DateTime.fromISO(date);
  return dt.toFormat("MMM dd, yyyy, h:mm:ss a");
};
export const defaultNumberOfYearsFixer = 3;

export const getCurrencySymbol = (code: CurrencyCode): CurrencySymbol => CurrenciesMap[code];

export const useAsyncCurrencyConverter = () => {
  const { customer } = useCustomerContext();
  const currency = CurrencyCodes[customer.settings?.currency || CurrencyCodes.USD];

  const asyncConvert = useCallback(
    (amount: number, day: Date) => asyncConvertCurrencyTo(amount, day, CurrencyCodes.USD, currency),
    [currency]
  );

  return {
    asyncConvert,
  } as const;
};

export const getCurrencyByCode = (code: string | undefined): CurrencyCode => {
  if (!code) {
    return CurrencyCodes.USD;
  }

  return Currencies.find((c) => c.value === code) ? (code as CurrencyCode) : CurrencyCodes.USD;
};

export const getCurrencyLabelByCode = (code: string): string => {
  const currency = Currencies.find((c) => c.value === code);
  if (currency) {
    return `${currency.symbol} ${currency.value}`;
  }
  return code;
};

export const formatCurrency = (
  value: number | string | undefined,
  code: CurrencyCode | undefined,
  maxFracDigits: number | undefined = 2,
  minFracDigits: number | undefined = undefined
): string => {
  if (value === undefined || value === "") {
    return "";
  }
  const currency = getCurrencyByCode(code);
  if (currency) {
    const v = value ? value : 0;
    const max = maxFracDigits ?? 2;
    return v.toLocaleString(undefined, {
      style: "currency",
      currency,
      maximumFractionDigits: max,
      minimumFractionDigits: minFracDigits ?? max,
    });
  } else {
    return `${code}${value}`;
  }
};

export const formatDecimalNumber = (
  value,
  maxFracDigits: number | undefined = undefined,
  minFracDigits: number | undefined = undefined
) => {
  const v = value ? value : 0;
  const max = maxFracDigits ?? 2;
  return v.toLocaleString(undefined, {
    style: "decimal",
    maximumFractionDigits: max,
    minimumFractionDigits: minFracDigits ?? max,
  });
};

const intlFormat = (n, d) => new Intl.NumberFormat().format(Math.round(n * Math.pow(10, d)) / Math.pow(10, d));

export const formatNumber = (n, d = 2) => {
  const abs = Math.abs(n);
  d = d < 1 ? 1 : d;
  if (abs >= 1e18) {
    return `${intlFormat(n / 1e18, d)}E`;
  }
  if (abs >= 1e15) {
    return `${intlFormat(n / 1e15, d)}P`;
  }
  if (abs >= 1e12) {
    return `${intlFormat(n / 1e12, d)}T`;
  }
  if (abs >= 1e9) {
    return `${intlFormat(n / 1e9, d)}G`;
  }
  if (abs >= 1e6) {
    return `${intlFormat(n / 1e6, d)}M`;
  }
  if (abs >= 1e3) {
    return `${intlFormat(n / 1e3, d)}k`;
  }
  return intlFormat(n, d);
};

export const roundWithCommas = (num: number) =>
  Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const assetTypeName = (type: string): string => {
  switch (type) {
    case "amazon-web-services":
      return "Amazon Web Services";
    case "amazon-web-services-standalone":
      return "Flexsave AWS Standalone";
    case "g-suite":
      return "Google Workspace";
    case "google-cloud":
      return "Google Cloud";
    case "google-cloud-standalone":
      return "Flexsave GCP Standalone";
    case "google-cloud-project":
      return "Google Cloud Project";
    case "office-365":
      return "Office 365";
    case "microsoft-azure":
      return "Microsoft Azure";
    case "superquery":
      return "superQuery";
    case "zendesk":
      return "Zendesk";
    case "bettercloud":
      return "BetterCloud";
    case "looker":
      return "Looker";
    case "navigator":
      return "DoiT Navigator";
    case "solve":
      return "DoiT Solve";
    case "solve-accelerator":
      return "DoiT Solve Accelerator";
    case "other":
      return "Other";
    case "snowflake":
      return "Snowflake";
    case "doit-cloud-intelligence":
      return "DoiT Cloud Intelligence";
    case "doit-cloud-intelligence-sp":
      return "DoiT Cloud Intelligence Single Purchase";
    default:
      return type;
  }
};

export const getCloudIcon = (cloud: AssetType, isDarkMode = false) => {
  switch (cloud) {
    case AssetTypeAmazonWebServices:
      return isDarkMode ? iconAwsDark : iconAws;
    case AssetTypeGoogleCloud:
      return googleCloudIcon;
    case AssetTypeMicrosoftAzure:
      return iconAzure;
    default:
      return null;
  }
};

export enum priorityCompanyDefaultCurrency {
  doit = CurrencyCodes.ILS,
  doitint = CurrencyCodes.USD,
  doituk = CurrencyCodes.GBP,
  doitaus = CurrencyCodes.AUD,
  doitde = CurrencyCodes.EUR,
  doitfr = CurrencyCodes.EUR,
  doitnl = CurrencyCodes.EUR,
  doitch = CurrencyCodes.CHF,
  doitca = CurrencyCodes.CAD,
  doitse = CurrencyCodes.SEK,
  doites = CurrencyCodes.EUR,
  doitie = CurrencyCodes.EUR,
  doitee = CurrencyCodes.EUR,
  doitsg = CurrencyCodes.SGD,
  doitjp = CurrencyCodes.JPY,
  doitid = CurrencyCodes.IDR,
  doitae = CurrencyCodes.AED,
  doitmx = CurrencyCodes.MXN,
  doitco = CurrencyCodes.COP,
}

export const priorityCompanyTransform = (id) => {
  switch (id) {
    case "doit":
      return "DoiT International (Israel)";
    case "doitint":
      return "DoiT International USA Inc.";
    case "doituk":
      return "DoiT International UK&I Ltd";
    case "doitaus":
      return "DoiT International AUS PTY Ltd";
    case "doitde":
      return "DoiT International, DACH GmbH";
    case "doitfr":
      return "DoiT International France SAS";
    case "doitnl":
      return "DoiT International NL BV";
    case "doitch":
      return "DoiT International CH SARL";
    case "doitca":
      return "DoiT Holdings International CA Ltd";
    case "doitse":
      return "DoiT International SE AB";
    case "doites":
      return "DoiT International Multi-Cloud Espana S.L";
    case "doitie":
      return "DoiT International Multi-Cloud Ireland Ltd";
    case "doitee":
      return "DoiT Estonia";
    case "doitsg":
      return "DoiT International Xinjiapo Pte. Ltd";
    case "doitjp":
      return "DoiT International Japan Co., Ltd";
    case "doitid":
      return "PT DoiT International Indonesia";
    case "doitae":
      return "Multi Cloud DoiT Information Technology Consulta";
    case "doitmx":
      return "DTI Multi-Cloud Mexico";
    case "doitco":
      return "DoiT International Colombia S.A.S";
    default:
      return "N/A";
  }
};

export const b64EncodeUnicode = (str) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(_match, p1) {
      // @ts-expect-error
      return String.fromCharCode(`0x${p1}`);
    })
  );

export const b64DecodeUnicode = (str) =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

const invalidPattern = /^(?:http[s]?:\/\/|(?:http[s]?:\/\/)?www\.).+$/;
const validPattern = /^([a-z0-9-_]+\.)*[a-z0-9][a-z0-9-_]+\.[a-z]{2,12}?$/;
export const isNakedDomainValid = (domain: string) => validPattern.test(domain) && !invalidPattern.test(domain);

export const validate = (inputData) => !inputData;

export const transformStatus = (status, t) => {
  switch (status) {
    case "new":
      return t("utils/common.new");
    case "open":
      return t("utils/common.open");
    case "pending":
      return t("utils/common.pending");
    case "hold":
      return t("utils/common.hold");
    case "solved":
      return t("utils/common.solved");
    case "closed":
      return t("utils/common.closed");
    default:
      return "";
  }
};
export const transformPriority = (priority, t) => {
  switch (priority) {
    case "low":
      return t("utils/common.low");
    case "normal":
      return t("utils/common.normal");
    case "high":
      return t("utils/common.high");
    case "urgent":
      return t("utils/common.urgent");
    default:
      return t("utils/common.low");
  }
};

export const RootOrgId = "root";

export enum PresetOrganizations {
  GCPOrg = "GCPOrg",
  AWSOrg = "AWSOrg",
  DOITOrg = "doit-international",
}

export const isPresetOrganization = (value: string): value is PresetOrganizations =>
  Object.values(PresetOrganizations).includes(value as PresetOrganizations);

export const getUserOrgID = (isDoitEmployee: boolean, userOrg: OrganizationWSnap | null | undefined): string =>
  isDoitEmployee ? PresetOrganizations.DOITOrg : (userOrg?.ref.id ?? RootOrgId);

export const partnerAssetsMap = {
  [AccountManagerCompanies.GCP]: AssetTypeGoogleCloud,
  [AccountManagerCompanies.AWS]: AssetTypeAmazonWebServices,
};

export const partnerOrgsMap = {
  [AccountManagerCompanies.GCP]: PresetOrganizations.GCPOrg,
  [AccountManagerCompanies.AWS]: PresetOrganizations.AWSOrg,
};

export const companiesLookup = {
  [AccountManagerCompanies.DOIT]: { name: "DoiT International", icon: iconDoit },
  [AccountManagerCompanies.AWS]: { name: "Amazon Web Services", icon: iconAws },
  [AccountManagerCompanies.GCP]: { name: "Google Cloud", icon: googleCloudIcon },
  [AccountManagerCompanies.MicrosoftAzure]: { name: "Microsoft Azure", icon: iconAzure },
};

export const clouds = [
  {
    name: "Amazon Web Services",
    hyphenName: AssetTypeAmazonWebServices,
    value: AccountManagerCompanies.AWS,
    icon: iconAws,
  },
  {
    name: "Google Cloud",
    hyphenName: AssetTypeGoogleCloud,
    value: AccountManagerCompanies.GCP,
    icon: googleCloudIcon,
  },
  {
    name: "Microsoft Azure",
    hyphenName: AssetTypeMicrosoftAzure,
    value: AccountManagerCompanies.MicrosoftAzure,
    icon: iconAzure,
  },
];

export type AccountRole = { name: string; value: string; vendors: string[] };
type TicketNotification = { name: "Off" | "Low" | "Normal" | "High" | "Urgent"; value: number; icon: "" | ">=" };
export const ticketNotification: TicketNotification[] = [
  { name: "Off", value: 0, icon: "" },
  { name: "Low", value: 1, icon: ">=" },
  { name: "Normal", value: 2, icon: ">=" },
  { name: "High", value: 3, icon: ">=" },
  { name: "Urgent", value: 4, icon: "" },
];

export const jobFunction = [
  { name: "Data Engineer/Data Analysts", value: 8 },
  { name: "Executive Team", value: 3 },
  { name: "Finance / Accounting", value: 2 },
  { name: "Founder", value: 6 },
  { name: "Legal / Purchasing", value: 5 },
  { name: "Management", value: 7 },
  { name: "Sales / Marketing", value: 4 },
  { name: "Software / Ops Engineer", value: 1 },
] as const;

export const permissions = [
  { name: "Users Manager", value: UserPermissions.IAM, visible: true },
  { name: "Billing Profile Admin", value: UserPermissions.BillingProfilesAdmin, visible: true },
  { name: "Assets Manager", value: UserPermissions.AssetsManager, visible: true },
  { name: "Flexsave Admin", value: UserPermissions.FlexibleRI, visible: true },
  { name: "Cloud Analytics", value: UserPermissions.CloudAnalytics, visible: true },
  { name: "Sandbox Admin", value: UserPermissions.SandboxAdmin, visible: true },
  { name: "Sandbox User", value: UserPermissions.SandboxUser, visible: true },
  { name: "Invoice Viewer", value: UserPermissions.InvoicesViewer, visible: true },
  { name: "Manage Settings", value: UserPermissions.Settings, visible: true },
  { name: "Spot Scaling Manager", value: UserPermissions.SpotScaling, visible: true },
  { name: "Contracts Viewer", value: UserPermissions.ContractsViewer, visible: true },
  { name: "Anomalies Viewer", value: UserPermissions.AnomaliesViewer, visible: true },
  { name: "Perks Viewer", value: UserPermissions.PerksViewer, visible: true },
  { name: "Issues Viewer", value: UserPermissions.IssuesViewer, visible: true },
  { name: "Budgets Manager", value: UserPermissions.BudgetsManager, visible: true },
  { name: "Metrics Manager", value: UserPermissions.MetricsManager, visible: true },
  { name: "Attributions Manager", value: UserPermissions.AttributionsManager, visible: true },
  { name: "Support Requester", value: UserPermissions.SupportRequester, visible: true },
  { name: "Ramp Plans Viewer", value: UserPermissions.RampPlansViewer, visible: true },
  { name: "Labels Manager", value: UserPermissions.LabelsManager, visible: true },
  { name: "Insights Manager", value: UserPermissions.InsightsManager, visible: true },
  { name: "DataHub Admin", value: UserPermissions.DataHubAdmin, visible: true },
  { name: "Threads Manager", value: UserPermissions.ThreadsManager, visible: true },
  { name: "CloudFlow Viewer", value: UserPermissions.CloudFlowViewer, visible: true },
] as const;

export const getPermissionsNames = (permissionsIds: (UserPermission | DoitRole)[]) =>
  permissionsIds.map((pId) => permissions.find((perm) => perm.value === pId)?.name ?? pId);

export const anomalyAlerts = [
  { name: "Information", value: 1 },
  { name: "Warning", value: 2 },
  { name: "Critical", value: 3 },
];

export enum anomalyAlertLevel {
  Information = 1,
  Warning = 2,
  Critical = 3,
}

export const getCloudConnectStatus = (statusID: number) => {
  switch (statusID) {
    case CategoryStatus.Healthy:
      return { text: CloudConnectStatusText.Healthy, color: green[600] };
    case CategoryStatus.Unhealthy:
      return { text: CloudConnectStatusText.Unhealthy, color: yellow[600] };
    case CategoryStatus.Critical:
      return { text: CloudConnectStatusText.Critical, color: red[500] };
    case CategoryStatus.Partial:
      return { text: CloudConnectStatusText.Partial, color: purple[500] };

    default:
      // Not Configured
      return { text: CloudConnectStatusText.NotConfigured, color: blue.A200 };
  }
};

export const statusStyles = (dark: boolean) => ({
  red: {
    backgroundColor: dark ? red[200] : red[700],
    color: dark ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
  yellow: {
    backgroundColor: dark ? yellow[400] : yellow[500],
    color: "rgba(0, 0, 0, 0.87)", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
  green: {
    backgroundColor: dark ? green[400] : green[700],
    color: dark ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
  grey: {
    backgroundColor: dark ? grey[500] : grey[300],
    color: "rgba(0, 0, 0, 0.87)", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
});

export enum CloudHealthStatus {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
  UNKNOWN = "unknown",
}

/**
 * Returns status label for cloud assets in a more professional manner than mere colors.
 * @param status Color string
 */
export const getStatusLabel = (status: CloudHealthStatus): string => {
  switch (status) {
    case CloudHealthStatus.RED:
      return "Critical";
    case CloudHealthStatus.YELLOW:
      return "Warning";
    case CloudHealthStatus.GREEN:
      return "Healthy";
    case CloudHealthStatus.UNKNOWN:
      return "Unknown";
  }
};

export const jsUcfirst = (text: string) => {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const awsShortenTitle = (title) => {
  if (title.indexOf("-") > -1 && title.includes("Amazon")) {
    title = title.substring(0, title.indexOf("-") - 1);
  }
  switch (title) {
    case "Amazon Elastic Compute Cloud":
      return "EC2";
    case "Amazon S3":
      return "S3";
    case "Amazon RDS Service":
      return "RDS";
    case "AWS (Support Business|Business Support)":
      return "Business Support";
    case "Amazon Virtual Private Cloud":
      return "VPC";
    case "AWS Direct Connect":
      return "Direct Connect";
    case "Amazon CloudFront":
      return "CloudFront";
    case "Amazon Elasticsearch Service":
      return "Elasticsearch";

    default:
      return title;
  }
};

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let newUrl;
  text.replace(urlRegex, (url) => {
    newUrl = url;
  });
  return newUrl;
};

// Change luxon DateTime time zone to UTC and set to 00:00:00
export const sanitizeDate = (dt: DateTime) => dt.toUTC().startOf("day");

// Change luxon DateTime to represent same date in UTC timezone and set time to 00:00:00
export const sanitizeKeepingLocalDate = (dt: DateTime) => dt.toUTC(0, { keepLocalTime: true }).startOf("day");

const getNoAccessURL = (history, missingPermissions, troubleshootUrl) => {
  const troubleshoot_urlTmp = troubleshootUrl ? `&troubleshoot_url=${troubleshootUrl}` : "";
  return `/noaccess?origin_url=${encodeURIComponent(
    history.location.pathname
  )}&missing_permissions=${encodeURIComponent(missingPermissions.join(","))}${troubleshoot_urlTmp}`;
};

// For example: `noAccess(history, ["Manage Settings", "Users"], "http://google.com");`
export const noAccess = (history, missingPermissions, troubleshootUrl) => {
  const url = getNoAccessURL(history, missingPermissions, troubleshootUrl);
  history.push(url);
};

// For example: `noAccess(history, ["Manage Settings", "Users"], "http://google.com");`
export const noAccessReplace = (history, missingPermissions, troubleshootUrl) => {
  const url = getNoAccessURL(history, missingPermissions, troubleshootUrl);
  history.replace(url);
};

export const classificationOptions = [
  { value: Classification.Business, name: "Business" },
  { value: Classification.Terminated, name: "Terminated" },
  { value: Classification.SuspendedForNonPayment, name: "Suspended for non-payment" },
  { value: Classification.Inactive, name: "Inactive" },
];

export const downloadFile = (filename, fileData) => {
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURIComponent(fileData)}`);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const capitalizeStartCase = (s) => (typeof s !== "string" ? s : s.charAt(0).toUpperCase() + s.slice(1));

export const addToObject = (obj, key, value, index) => {
  const temp = {};
  let i = 0;
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key && value) {
        temp[key] = value;
      }
      temp[prop] = obj[prop];
      i++;
    }
  }
  // If no index is specified, add to the end
  if (!index && key && value) {
    temp[key] = value;
  }
  return temp;
};

export const copyToClipboard = (e, showSnackbar, textToCopy) => {
  const target = e.target;
  const parent = target.parentElement;
  if (textToCopy) {
    copy(textToCopy);
  } else {
    copy(e.target.id !== "icon" ? e.target.innerText : parent.textContent);
  }
  showSnackbar({
    message: "Copied to Clipboard",
    variant: "info",
    autoHideDuration: 1000,
  });
};

const isNumeric = <T,>(e: KeyboardEvent<T>) => e.key >= "1" || e.key <= "0";

export const onKeyPressPreventNonNumeric = <T,>(e: KeyboardEvent<T>) => {
  if (!isNumeric(e)) {
    e.preventDefault();
  }
};

export const onKeyPressPreventNonNumericAllowFloatNegative = <T,>(e: KeyboardEvent<T>) => {
  if (e.key !== "-" && e.key !== "." && !isNumeric(e)) {
    e.preventDefault();
  }
};

export const getDateTimeFromFirestoreTimestamp = (timestamp) => DateTime?.fromJSDate(timestamp?.toDate());

export const getCustomerName = async (ref) => {
  const customerDocSnap = await ref.get();
  const customer = customerDocSnap.data();
  return customer.name;
};

// getCustomerAccountManagers receives a customer reference and returns an array
// of the DoiT account managers (FSR/SAM) who are assigned to this customer
export const getCustomerAccountManagers = async (customerRef) => {
  const customerDocSnap = await customerRef.get();
  const customer = customerDocSnap.data();
  const accountManagers = [];
  if (customer.accountManagers?.doit) {
    for (const key in customer.accountManagers.doit) {
      if (customer.accountManagers.doit[key]?.ref) {
        const docSnap = await customer.accountManagers.doit[key].ref.get();
        if (docSnap.exists()) {
          // @ts-expect-error
          accountManagers.push(docSnap.data());
        }
      }
    }
  }
  return accountManagers;
};

export const randomLoadingMessage = () => {
  const loadingLines = [
    "Locating the required gigapixels to render...",
    "Spinning up the hamster...",
    "Shovelling coal into the server...",
    "Programming the flux capacitor...",
  ];

  return sample(loadingLines);
};

export const preventEnterEvent: KeyboardEventHandler<HTMLFormElement> = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

/**
 * Provides a yes/no in place of true/false If the value is missing or nullish, it will fall back on "No".
 * @param bool the boolean value to convert to words
 */
export const humanBoolean = (bool?: boolean): "Yes" | "No" => (bool ? "Yes" : "No");

export const truncate = (str: string, n: number, useWordBoundary: boolean) => {
  if (!str || str?.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1);
  return `${useWordBoundary ? subString.substring(0, subString.lastIndexOf(" ")) : subString}...`;
};

export const formatToLocaleTimestamp = (ts: FirestoreTimestamp): string => {
  const dt = DateTime.fromJSDate(ts?.toDate());
  return dt?.isValid ? dt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : "";
};

export const isDoitEmployeeOrPartner = ({
  isDoitEmployee,
  isDoitPartner,
}: {
  isDoitEmployee: boolean;
  isDoitPartner: boolean;
}): boolean => isDoitEmployee || isDoitPartner;

export enum GlobalDashboardId {
  Home = "acczPSL1V2PY45mcBwBV", // Account/Home/default dashboard
  Pulse = "kr7df9r9FQmSH67O2wkO",
  BQLens = "uItbeGssgbOpmpOKQ9wj",
  AWSLens = "YnJfHkFLw7lsLINCkISG",
  SaaSAWSLens = "CBFt28GqijUr2ozwk2vx",
  GCPLens = "ixThO1hY3IWlo8O8Py2q",
  SaaSGCPLens = "D1JeYOEi2xSPjh9upVCI",
  GKELensv2 = "U6R46sYmgx8L55WbHvpb",
  AzureLens = "pnWLfwok8rB7hsK1YKGM",
  EKSLens = "JnC6EMX44G2d0mOv6N5a",
  SnowflakeLens = "8mLGGdUzxD0ubRjaxwjq",
  AWSMAPLens = "UWjzapLDJZzLBIF4EpDD",
}

export const GlobalDashboardsById = {
  [GlobalDashboardId.Pulse]: { type: "pulse", name: "Pulse" },
  [GlobalDashboardId.BQLens]: { type: "superquery", name: "BigQuery Lens" },
  [GlobalDashboardId.AWSLens]: { type: "AWS", name: "AWS Lens" },
  [GlobalDashboardId.SaaSAWSLens]: { type: "saas-aws-lens", name: "AWS Lens" },
  [GlobalDashboardId.GCPLens]: { type: "gcp-lens", name: "GCP Lens" },
  [GlobalDashboardId.SaaSGCPLens]: { type: "saas-gcp-lens", name: "GCP Lens" },
  [GlobalDashboardId.GKELensv2]: { type: "gke-lens-v2", name: "GKE Lens" },
  [GlobalDashboardId.AzureLens]: { type: "azure-lens", name: "Azure Lens" },
  [GlobalDashboardId.EKSLens]: { type: "eks-lens", name: "EKS Lens" },
  [GlobalDashboardId.SnowflakeLens]: { type: "snowflake-lens", name: "Snowflake Lens" },
  [GlobalDashboardId.AWSMAPLens]: { type: "aws-map-lens", name: "AWS MAP Lens" },
};

export const getDashboardNameById = (dashboardType: string | null | undefined, name: string): string =>
  dashboardType ? (GlobalDashboardsById[dashboardType]?.name ?? name) : name;

export const isMac = () => window.navigator.userAgent.indexOf("Macintosh") !== -1;

export const isSaaSConsoleByType = (
  customer: Customer | undefined,
  isProductOnlyCustomer: boolean,
  type: SaaSConsoleType,
  checkHybrid: boolean
): boolean => {
  if (!checkHybrid && !isProductOnlyCustomer) {
    return false;
  }

  switch (type) {
    case SaaSConsoleType.AWS:
      return !!customer?.enabledSaaSConsole?.AWS;
    case SaaSConsoleType.GCP:
      return !!customer?.enabledSaaSConsole?.GCP;
    default:
      return false;
  }
};

export const getSaaSConsoleOnboardingUrl = (type: SaaSConsoleType): string =>
  `cloud-onboarding-${type.toLocaleLowerCase()}`;

export const getSaaSConsoleOnboardingRoute = (customer: Customer | undefined, type: SaaSConsoleType): string => {
  if (customer === undefined) {
    return "";
  }
  const { id } = customer;
  return customersRoute(id, getSaaSConsoleOnboardingUrl(type));
};

export const requiresStandaloneConfiguration = (customer: Customer, isProductOnlyCustomer: boolean): boolean =>
  !!(
    isProductOnlyCustomer &&
    customer.enabledFlexsave &&
    !customer.enabledFlexsave.GCP &&
    !customer.enabledFlexsave.AWS
  );

export const requiresSaaSConsoleConfiguration = (customer: Customer, isProductOnlyCustomer: boolean): boolean =>
  isProductOnlyCustomer &&
  !customer.enabledFlexsave &&
  !customer.enabledSaaSConsole?.AZURE &&
  !customer.enabledSaaSConsole?.GCP &&
  !customer.enabledSaaSConsole?.AWS &&
  !customer.onboarding?.navigator?.providers.length &&
  !customer.onboarding?.solve?.providers.length;

export const getAccountTeamRole = (
  roles: AccountRole[],
  accountManager: WithFirebaseModel<AccountManagerModel>
): string => {
  if (accountManager.role && roles) {
    const matchedRole = roles.find(
      (role) => role.value === accountManager.role && role.vendors.includes(accountManager.company)
    );
    return matchedRole ? matchedRole.name : "";
  }
  return "";
};

export function stringifyTimestamp({
  timeStamp,
  formatOpts = DateTime.DATETIME_MED_WITH_SECONDS,
  fallback = "",
}: {
  timeStamp: Pick<FirestoreTimestamp, "toDate"> | null | undefined;
  formatOpts?: DateTimeFormatOptions;
  fallback?: string;
}): string {
  if (!timeStamp) {
    return fallback;
  }
  const date = DateTime.fromJSDate(timeStamp.toDate());
  if (!date.isValid) {
    return fallback;
  }
  return date.toLocaleString(formatOpts);
}

export const sortAlphabetically = (a: string, b: string, AtoZ = true): number => {
  if (a === b) {
    return 0;
  }

  switch (AtoZ) {
    case true:
      return a < b ? -1 : 1;
    case false:
      return a > b ? -1 : 1;
  }
};

export function convertNullToArray<T>(arr?: T[] | null): T[] | [] {
  return arr ?? [];
}

export const scrollToId = (id: string, offset = 0) => {
  const el = document.getElementById(id);

  if (!el) {
    return;
  }

  window.scrollTo({ top: el.offsetTop - offset, behavior: "smooth" });
};

export const httpStatusMap: Record<number, string> = {
  100: "Continue",
  101: "Switching Protocols",
  102: "Processing", // WebDAV

  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  207: "Multi-Status", // WebDAV
  208: "Already Reported", // WebDAV
  226: "IM Used",

  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  307: "Temporary Redirect",
  308: "Permanent Redirect",

  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot", // RFC 2324
  421: "Misdirected Request",
  422: "Unprocessable Entity", // WebDAV
  423: "Locked", // WebDAV
  424: "Failed Dependency", // WebDAV
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",

  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage", // WebDAV
  508: "Loop Detected", // WebDAV
  510: "Not Extended",
  511: "Network Authentication Required",
};
