// useWizardForm.js
import { useState } from "react";

import { stepLabels } from "../../Pages/CommitmentManager/consts";

export const useWizardForm = (steps: React.FC[]) => {
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    setCurrentStep((prev) => Math.min(prev + 1, stepLabels.length - 1));
  };

  const back = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  return {
    currentStep,
    next,
    back,
    StepComponent: steps[currentStep],
  };
};

export default useWizardForm;
