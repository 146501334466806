import { Step, StepConnector, stepConnectorClasses, StepLabel, stepLabelClasses, Stepper, styled } from "@mui/material";

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    /**
     * Removes the label width from the label container,
     * so that the line does not get pushed out.
     * Since the label would be a dynamic size, we wouldn't
     * be able to account for that. Utilizing absolute positioning
     * allows us to avoid that.
     */
    position: "absolute",
    width: "auto",
    /**
     * Moves the label down. Needs to be at least "100%" to avoid
     * having the label centered on the icon.
     */
    transform: "translateY(200%)",
  },
}));

const StyledStepConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    // allow for utilization of "top", "left", and "right"
    position: "relative",
    // used to vertically center line
    top: 11,
  },
}));

export const FullWidthStepper = ({ steps, activeStep }) => (
  <Stepper
    activeStep={activeStep}
    sx={{
      alignItems: "flex-start",
      /**
       * since the label doesn't account for width,
       * you'll need to apply a margin bottom
       **/
      mb: 4,
    }}
    connector={<StyledStepConnector />}
  >
    {steps.map((label) => (
      <Step key={label}>
        <StyledStepLabel>{label}</StyledStepLabel>
      </Step>
    ))}
  </Stepper>
);
