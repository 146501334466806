import { TextField, type TextFieldProps } from "@mui/material";
import { type FormikErrors, type FormikTouched } from "formik";

import { type Commitment } from "./types";

type Props = TextFieldProps & {
  value?: string;
  errors: FormikErrors<Commitment> | string | undefined;
  touched: FormikTouched<Commitment> | string | undefined;
  name: string;
};

function isFormikErrors(errors: FormikErrors<Commitment> | string | undefined): errors is FormikErrors<Commitment> {
  return typeof errors === "object" && errors !== null && !Array.isArray(errors);
}

function isFormikTouched(
  touched: FormikTouched<Commitment> | string | undefined
): touched is FormikTouched<Commitment> {
  return typeof touched === "object" && touched !== null && !Array.isArray(touched);
}

export const CustomTextField = ({ value, errors, touched, name, ...props }: Props) => {
  const error = isFormikErrors(errors) && isFormikTouched(touched) && !!errors[name] && touched[name];

  return <TextField name={name} value={value} error={error} helperText={error && errors?.[name]} {...props} />;
};
