import { useEffect, useMemo } from "react";

import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAnalyticsDimensions } from "../../hooks/cloudAnalytics/useAnalyticsDimensions";

export const useDimension = (dimensionId) => {
  const { metadata: metadataSnapshots, fetchMetadata } = useCloudAnalyticsContext();
  const { dimensions } = useAnalyticsDimensions({
    metadataSnapshots,
  });

  const dimension = useMemo(() => dimensions?.find((m) => m.id === dimensionId), [dimensions, dimensionId]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  return dimension;
};
