/**
 * Defines the category of each page and the order of display
 */
import LaunchIcon from "@mui/icons-material/Launch";
import { type TFunction } from "i18next";

import { type NavigationCategory } from "../types";

const sharedNavigationCategories: (t: TFunction<"navigation">) => NavigationCategory[] = (t) => [
  {
    displayName: t("manage"),
    items: [
      {
        displayName: "Customers",
        pageId: "customers",
      },
      {
        displayName: "Financials",
        pageId: "billing:invoicing",
      },
      {
        displayName: "Account managers",
        pageId: "account-managers",
      },
      {
        displayName: "Cloud incidents",
        pageId: "known-issues",
      },
      {
        displayName: "Flexsave GCP Ops",
        pageId: "flexsave-gcp-ops-customers",
        subPages: [
          "flexsave-gcp-ops-customers:details",
          "flexsave-gcp-ops-bulk-purchases",
          "flexsave-gcp-ops-approval-purchases",
          "flexsave-gcp-ops-manual-purchase",
        ],
      },
      {
        displayName: "Flexsave AWS Ops",
        pageId: "flexsave-aws-operations",
      },
      {
        displayName: "AWS AC",
        pageId: "aws-account-generator",
      },
      {
        displayName: "Marketplace",
        pageId: "marketplace:gcp:accounts",
      },
      {
        displayName: "WHOIS",
        pageId: "whois",
      },
      {
        displayName: "MPA Ops",
        pageId: "master-payer-account",
      },
      {
        displayName: "Tiers",
        pageId: "tier-management",
      },
    ],
  },
  {
    displayName: "Tiers",
    isSecondLevel: true,
    items: [
      {
        displayName: "Tiers",
        pageId: "tier-management",
      },
      {
        displayName: "Entitlements",
        pageId: "entitlements-management",
      },
    ],
  },
  {
    displayName: "Marketplace",
    isSecondLevel: true,
    items: [
      {
        displayName: "GCP accounts",
        pageId: "marketplace:gcp:accounts",
      },
      {
        displayName: "GCP entitlements",
        pageId: "marketplace:gcp:entitlements",
      },
      {
        displayName: "GCP adjustments",
        pageId: "marketplace:gcp:adjustments",
      },
      {
        displayName: "AWS accounts",
        pageId: "marketplace:aws:accounts",
      },
      {
        displayName: "AWS entitlements",
        pageId: "marketplace:aws:entitlements",
      },
    ],
  },
  {
    displayName: t("home"),
    pageId: "home-page",
    items: [],
  },
  {
    displayName: t("dashboard"),
    pageId: "customer-dashboards",
    items: [],
  },
];

// the grouping of the pages into categories of nav2
export const navigationCategories: (t: TFunction<"navigation">) => NavigationCategory[] = (t) => [
  ...sharedNavigationCategories(t),
  {
    displayName: "Get started",
    pageId: "get-started",
    items: [],
  },
  {
    displayName: "Financials",
    isSecondLevel: true,
    items: [
      {
        displayName: "Cash",
        pageId: "collection:cash",
      },
      {
        displayName: "Invoice Adjustments",
        pageId: "adjustments:global",
      },
      {
        displayName: "Credits",
        pageId: "credits:global",
      },
      {
        displayName: "Debt",
        pageId: "collection:debt",
      },
      {
        displayName: "AWS Pricebooks",
        pageId: "price-books:aws",
      },
      {
        displayName: "GCP Pricebooks",
        pageId: "price-books:gcp",
      },
      {
        displayName: "Invoicing",
        pageId: "billing:invoicing",
      },
      {
        displayName: "Invoicing Hold",
        pageId: "billing:on-hold",
      },
      {
        displayName: "Draft Invoices",
        pageId: "billing:draft-invoices",
      },
      {
        displayName: "Billing Tools",
        pageId: "billing:billing-tools",
      },
      {
        displayName: "COGS",
        pageId: "billing:cogs",
      },
      {
        displayName: "PLES",
        pageId: "partner-led-enterprise-support",
      },
    ],
  },
  {
    displayName: t("analytics"),
    items: [
      {
        displayName: "All",
        pageId: "analytics:resources",
        subPages: ["analytics:labels"],
      },
      { displayName: "Allocations", pageId: "analytics:allocations" },
      {
        displayName: "Reports",
        pageId: "analytics:reports",
        subPages: ["analytics:reports", "analytics:reports:details", "analytics:reports:create"],
        subItems: [
          {
            displayName: "New report",
            pageId: "analytics:reports:create",
          },
          {
            displayName: "View reports",
            pageId: "analytics:reports",
          },
        ],
      },
      {
        displayName: "Report templates",
        pageId: "analytics:report-templates",
      },
      {
        displayName: "Attributions",
        pageId: "analytics:attributions",
        subPages: ["analytics:attributions:details", "analytics:attributions:create"],
        subItems: [
          {
            displayName: "New attribution",
            pageId: "analytics:attributions:create",
          },
          {
            displayName: "View attributions",
            pageId: "analytics:attributions",
          },
        ],
      },
      {
        displayName: "Attribution groups",
        pageId: "analytics:attribution-groups",
        subPages: ["analytics:attribution-groups:edit", "analytics:attribution-groups:create"],
        subItems: [
          {
            displayName: "New attribution group",
            pageId: "analytics:attribution-groups:create",
          },
          {
            displayName: "View attribution groups",
            pageId: "analytics:attribution-groups",
          },
        ],
      },
      {
        displayName: "Metrics",
        pageId: "analytics:metrics",
        subPages: ["analytics:metrics:details", "analytics:metrics:create"],
        subItems: [
          {
            displayName: "New metric",
            pageId: "analytics:metrics:create",
          },
          {
            displayName: "View metrics",
            pageId: "analytics:metrics",
          },
        ],
      },
    ],
  },
  {
    displayName: t("savings"),
    items: [
      {
        displayName: "Flexsave AWS",
        pageId: "flexsave-aws",
      },
      {
        displayName: "Spot Scaling",
        pageId: "spot-scaling",
        subPages: ["spot-scaling:apply"],
      },
    ],
  },
  {
    displayName: t("governance"),
    items: [
      {
        displayName: "Alerts",
        pageId: "analytics:alerts",
        subPages: ["analytics:alerts:create", "analytics:alerts:details"],
      },
      {
        displayName: "Budgets",
        pageId: "analytics:budgets",
        subPages: ["analytics:budgets:create", "analytics:budgets:details"],
      },
      {
        displayName: "Cost anomalies",
        pageId: "anomaly-detection",
        subPages: ["anomaly-detection:details"],
      },
      {
        displayName: "Insights",
        pageId: "insights",
        subPages: ["insights:details", "insights:newInsight"],
      },
      {
        displayName: "Sandbox accounts",
        pageId: "sandbox",
      },
      {
        displayName: "Cloud incidents",
        pageId: "known-issues:customer",
      },
      {
        displayName: "Threads",
        pageId: "threads",
        subPages: ["threads:details"],
      },
      {
        displayName: "CloudFlow",
        pageId: "cloudflow",
        subPages: ["cloudflow:history", "cloudflow:templates"],
      },
    ],
  },
  {
    displayName: t("services"),
    items: [
      {
        displayName: t("services.consulting-support"),
        pageId: "support",
        subPages: ["support:tickets", "support:new", "support:followup"],
      },
      {
        displayName: t("services.professional-services"),
        pageId: "professional-services",
        subPages: ["professional-services:create", "professional-services:edit", "professional-services:view"],
      },
      {
        displayName: t("services.isv-solutions"),
        pageId: "isv-solutions",
        subPages: ["isv-solutions:create", "isv-solutions:edit", "isv-solutions:view"],
      },
      {
        displayName: t("services.live-ondemand"),
        pageId: "live-product-workshop",
        icon: LaunchIcon,
      },
    ],
  },
  {
    displayName: t("billing"),
    items: [
      {
        displayName: "Assets",
        pageId: "assets",
        subPages: ["assets:all", "assets:g-suite:create", "assets:office-365:create"],
      },
      {
        displayName: "Billing profiles",
        pageId: "billing-profiles",
        subPages: ["entity:edit"],
      },
      {
        displayName: "Contracts",
        pageId: "contracts:list",
        subPages: ["contracts:edit", "contracts:create"],
      },
      {
        displayName: "Invoices",
        pageId: "invoices",
        subPages: ["invoices:details"],
      },
      {
        displayName: "Order history",
        pageId: "orders",
      },
      {
        displayName: "Commitment manager",
        pageId: "commitment-manager",
      },
      {
        displayName: "Ramp plans",
        pageId: "ramp-plans",
        subPages: ["ramp-plans"],
      },
      {
        displayName: "Credits",
        pageId: "credits:customer",
      },
      {
        displayName: "Invoice Adjustments",
        pageId: "adjustments:customer",
      },
    ],
  },
  {
    displayName: "Settings",
    items: [
      {
        displayName: "General",
        pageId: "settings:general",
      },
      {
        displayName: "Account Managers",
        pageId: "settings:account-managers",
      },
      {
        displayName: "Notifications",
        pageId: "notifications",
      },
      {
        displayName: "Identity & access",
        pageId: "iam:users",
        subPages: ["iam:users:details"],
      },
      {
        displayName: "Integrations",
        pageId: "integrations:zapier",
      },
      {
        displayName: "DataHub",
        pageId: "datasets",
        subPages: ["datasets:details"],
      },
      {
        displayName: "Amazon Web Services",
        pageId: "settings:aws",
        subPages: ["settings:aws:account", "settings:aws:link"],
      },
      {
        displayName: "Google Cloud",
        pageId: "settings:gcp",
      },
    ],
  },
  {
    displayName: "Integrations",
    isSecondLevel: true,
    items: [
      {
        displayName: "Zapier",
        pageId: "integrations:zapier",
      },
      {
        displayName: "Slack",
        pageId: "integrations:slack",
      },
      {
        displayName: "Jira",
        pageId: "integrations:jira",
      },
      {
        displayName: "Microsoft Teams",
        pageId: "integrations:ms-teams",
      },
    ],
  },
  {
    displayName: "Identity & access",
    isSecondLevel: true,
    items: [
      {
        displayName: "Users",
        pageId: "iam:users",
      },
      {
        displayName: "Roles",
        pageId: "iam:roles",
        subPages: ["iam:roles:details"],
      },
      {
        displayName: "Organizations",
        pageId: "iam:organizations",
        subPages: ["iam:organizations:details", "iam:organizations:create"],
        entitlement: "platform:IAMOrg",
      },
      {
        displayName: "Single sign-on",
        pageId: "iam:sso",
      },
      {
        displayName: "Settings",
        pageId: "iam:auth-provider",
      },
      {
        displayName: "Account assumption",
        pageId: "iam:account-assumption",
      },
    ],
  },
  {
    displayName: "Flexsave GCP",
    isSecondLevel: true,
    items: [
      {
        displayName: "Customers",
        pageId: "flexsave-gcp-ops-customers",
      },
      {
        displayName: "Bulk purchases",
        pageId: "flexsave-gcp-ops-bulk-purchases",
      },
      {
        displayName: "Approvals",
        pageId: "flexsave-gcp-ops-approval-purchases",
      },
    ],
  },
];

export const standaloneNavigationCategories: (t: TFunction<"navigation">) => NavigationCategory[] = (t) => [
  ...sharedNavigationCategories(t),
  {
    displayName: "Savings",
    items: [
      {
        displayName: "Flexsave AWS",
        pageId: "flexsave-aws",
      },
      {
        displayName: "Spot Scaling",
        pageId: "spot-scaling",
        subPages: ["spot-scaling:apply"],
      },
    ],
  },
  {
    displayName: "Analytics",
    items: [
      {
        displayName: "Reports",
        pageId: "analytics:reports",
        subPages: ["analytics:reports:details", "analytics:reports:create"],
        subItems: [
          {
            displayName: "New report",
            pageId: "analytics:reports:create",
          },
          {
            displayName: "View reports",
            pageId: "analytics:reports",
          },
        ],
      },
      {
        displayName: "Report templates",
        pageId: "analytics:report-templates",
      },
      {
        displayName: "Attributions",
        pageId: "analytics:attributions",
        subPages: ["analytics:attributions:details", "analytics:attributions:create"],
        subItems: [
          {
            displayName: "New attribution",
            pageId: "analytics:attributions:create",
          },
          {
            displayName: "View attributions",
            pageId: "analytics:attributions",
          },
        ],
      },
      {
        displayName: "Attribution groups",
        pageId: "analytics:attribution-groups",
        subPages: ["analytics:attribution-groups:edit", "analytics:attribution-groups:create"],
        subItems: [
          {
            displayName: "New attribution group",
            pageId: "analytics:attribution-groups:create",
          },
          {
            displayName: "View attribution groups",
            pageId: "analytics:attribution-groups",
          },
        ],
      },
      {
        displayName: "Metrics",
        pageId: "analytics:metrics",
        subPages: ["analytics:metrics:details", "analytics:metrics:create"],
        subItems: [
          {
            displayName: "New metric",
            pageId: "analytics:metrics:create",
          },
          {
            displayName: "View metrics",
            pageId: "analytics:metrics",
          },
        ],
      },
    ],
  },
  {
    displayName: "Governance",
    items: [
      {
        displayName: "Alerts",
        pageId: "analytics:alerts",
        subPages: ["analytics:alerts:create", "analytics:alerts:details"],
      },
      {
        displayName: "Budgets",
        pageId: "analytics:budgets",
        subPages: ["analytics:budgets:create", "analytics:budgets:details"],
      },
      {
        displayName: "Cost anomalies",
        pageId: "anomaly-detection",
        subPages: ["anomaly-detection:details"],
      },
      {
        displayName: "Insights",
        pageId: "insights",
        subPages: ["insights:details", "insights:newInsight"],
      },
      {
        displayName: "Cloud incidents",
        pageId: "known-issues:customer",
      },
      {
        displayName: "Threads",
        pageId: "threads",
        subPages: ["threads:details"],
      },
      {
        displayName: "CloudFlow",
        pageId: "cloudflow",
      },
    ],
  },
  {
    displayName: "Services",
    items: [
      {
        displayName: "Consulting and support",
        pageId: "support",
        subPages: ["support:tickets", "support:new", "support:followup"],
      },
      {
        displayName: "Professional services",
        pageId: "professional-services",
        subPages: ["professional-services:create", "professional-services:edit", "professional-services:view"],
      },
      {
        displayName: "ISV solutions",
        pageId: "isv-solutions",
        subPages: ["isv-solutions:create", "isv-solutions:edit", "isv-solutions:view"],
      },
      {
        displayName: "Live & on-demand",
        pageId: "live-product-workshop",
        icon: LaunchIcon,
      },
    ],
  },
  {
    displayName: "Billing",
    items: [
      {
        displayName: "Assets",
        pageId: "assets",
        subPages: ["assets:all"],
      },
      {
        displayName: "Billing profiles",
        pageId: "billing-profiles",
        subPages: ["entity:edit"],
      },
      {
        displayName: "Contracts",
        pageId: "contracts:list",
        subPages: ["contracts:edit"],
      },
      {
        displayName: "Invoices",
        pageId: "invoices",
        subPages: ["invoices:details"],
      },
    ],
  },
  {
    displayName: "Settings",
    items: [
      {
        displayName: "General",
        pageId: "settings:general",
      },
      {
        displayName: "Account Managers",
        pageId: "settings:account-managers",
      },
      {
        displayName: "Notifications",
        pageId: "notifications",
      },
      {
        displayName: "Identity & access",
        pageId: "iam:users",
        subPages: ["iam:users:details"],
      },
      {
        displayName: "Integrations",
        pageId: "integrations:zapier",
      },
      {
        displayName: "DataHub",
        pageId: "datasets",
        subPages: ["datasets:details"],
      },
      {
        displayName: "Amazon Web Services",
        pageId: "settings:aws",
      },
      {
        displayName: "Google Cloud",
        pageId: "settings:gcp",
      },
    ],
  },
  {
    displayName: "Integrations",
    isSecondLevel: true,
    items: [
      {
        displayName: "Zapier",
        pageId: "integrations:zapier",
      },
      {
        displayName: "Slack",
        pageId: "integrations:slack",
      },
      {
        displayName: "Jira",
        pageId: "integrations:jira",
      },
      {
        displayName: "Microsoft Teams",
        pageId: "integrations:ms-teams",
      },
    ],
  },
  {
    displayName: "Identity & access",
    isSecondLevel: true,
    items: [
      {
        displayName: "Users",
        pageId: "iam:users",
      },
      {
        displayName: "Roles",
        pageId: "iam:roles",
        subPages: ["iam:roles:details"],
      },
      {
        displayName: "Single sign-on",
        pageId: "iam:sso",
      },
      {
        displayName: "Settings",
        pageId: "iam:auth-provider",
      },
      {
        displayName: "Account assumption",
        pageId: "iam:account-assumption",
      },
    ],
  },
];

export const partnerNavigationCategories: NavigationCategory[] = [
  {
    displayName: "Flexsave",
    pageId: "flexsave-gcp",
    items: [],
  },
  {
    displayName: "Analytics",
    items: [
      {
        displayName: "Reports",
        pageId: "analytics:reports",
        subPages: ["analytics:reports:details"],
      },
      {
        displayName: "Report templates",
        pageId: "analytics:report-templates",
      },
      {
        displayName: "Attributions",
        pageId: "analytics:attributions",
        subPages: ["analytics:attributions:details"],
      },
    ],
  },
  {
    displayName: "Billing",
    items: [
      {
        displayName: "Assets",
        pageId: "assets",
        subPages: ["assets:all"],
      },
      {
        displayName: "Contracts",
        pageId: "contracts:list",
        subPages: ["contracts:edit"],
      },
      {
        displayName: "Invoices",
        pageId: "invoices",
        subPages: ["invoices:details"],
      },
    ],
  },
  {
    displayName: "Support",
    pageId: "support",
    items: [],
  },
  {
    displayName: "Settings",
    items: [
      {
        displayName: "General",
        pageId: "settings:general",
      },
      {
        displayName: "Identity & access",
        pageId: "iam:users",
        subPages: ["iam:users:details"],
      },
      {
        displayName: "Google Cloud",
        pageId: "settings:gcp",
      },
    ],
  },
  {
    displayName: "Identity & access",
    isSecondLevel: true,
    items: [
      {
        displayName: "Users",
        pageId: "iam:users",
      },
      {
        displayName: "Roles",
        pageId: "iam:roles",
        subPages: ["iam:roles:details"],
      },
    ],
  },
];
