import { type JSX } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";

type InfoTooltipProps = {
  description: string;
  children: JSX.Element;
};

export const InfoTooltip = ({ children, description }: InfoTooltipProps) => (
  <Box display="flex" alignItems="center">
    {children}

    <Box px={1} display="flex" alignItems="center">
      <Tooltip title={description} arrow placement="top">
        <InfoOutlinedIcon cursor="pointer" color="action" fontSize="small" />
      </Tooltip>
    </Box>
  </Box>
);
