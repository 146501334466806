import * as Yup from "yup";

import { type MetadataOption } from "../../types";

export const validationSchema = (currentStep) => {
  switch (currentStep) {
    case 0:
      return Yup.object({
        selectedOption: Yup.string().required("Provider selection is required"),
        planName: Yup.string().required("Plan name is required"),
        selectedContracts: Yup.array(),
        currency: Yup.string().required("Currency is required"),
      });
    case 1:
      return Yup.object({
        totalValue: Yup.number().typeError("Must be a number").required("Total value is required"),
        startDate: Yup.string().required("Start date is required"),
        commitments: Yup.array().of(
          Yup.object().shape({
            periodLength: Yup.number().max(100, "Value must be under 100").required("Period length is required"),
            commitmentValue: Yup.number().typeError("Must be a number").required("Commitment value is required"),
          })
        ),
        excess: Yup.boolean(),
        excessValue: Yup.number()
          .typeError("Must be a number")
          .when("excess", {
            is: true,
            then: (schema) => schema.required("Excess value is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        shortfall: Yup.boolean(),
        shortfallValue: Yup.number()
          .typeError("Must be a number")
          .when("shortfall", {
            is: true,
            then: (schema) => schema.required("Shortfall value is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
      });
    case 2:
      return Yup.object({
        step3Field: Yup.string().required("Required"),
      });
    default:
      return Yup.object();
  }
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const addMonths = (date: Date, months: number) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
};

export const getFieldName = (selected: MetadataOption) => {
  const label = selected.data.label ?? selected.data.field;
  const plural = selected.data.plural ?? label;
  return plural;
};
