import { type ReactNode } from "react";

import { type FormikErrors } from "formik";

import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { PanelWrapper } from "../common/PanelWrapper";
import { AutocompleteCurrency } from "../components/AutocompleteCurrency";

export const PAYMENT_PANEL_ID = "payments";

type Props = {
  setFieldValue: (field: string, value: string | null) => void;
  values: CreateBillingProfileData;
  errors: FormikErrors<CreateBillingProfileData>;
  inEditMode: boolean;
  children?: ReactNode;
};

export const PaymentPanel = ({ setFieldValue, values, errors, inEditMode, children }: Props) => (
  <PanelWrapper title="Payment method" subtitle="Payment details" id={PAYMENT_PANEL_ID} paddingTop={9}>
    <AutocompleteCurrency
      value={values.currency}
      onChange={(value) => {
        setFieldValue("currency", value);
      }}
      disabled={inEditMode}
      error={errors.currency}
    />
    {children}
  </PanelWrapper>
);
