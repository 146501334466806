import { type Metadata } from "@doitintl/cmp-models";
import { FormControl, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

import { FilterDialogContainer } from "../../Components/Dialogs/CloudAnalytics/FilterDialogContainer";
import { useDimension } from "../../Components/Dialogs/CloudAnalytics/hooks";
import { type FixedFilters } from "../CloudAnalytics/utilities";
import { AttributionChips } from "./AttributionChips";
import { type Filter } from "./types";

type Props = {
  dimensionId: `${Metadata}:${FixedFilters}`;
  value: Filter;
  onChange: (value: Filter) => void;
};

export const AttributeSelector = ({ dimensionId, value, onChange }: Props) => {
  const dimension = useDimension(dimensionId);

  if (!dimension) {
    return null;
  }

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    onChange({ ...value, exclude: event.target.value === "is not" });
  };

  return (
    <Stack spacing={3} direction="row">
      <FilterDialogContainer
        onSave={(filter) => {
          const newFilter: Filter = {
            exclude: value.exclude,
            key: dimensionId,
            values: filter.values ?? [],
            regexp: filter.regexp ?? "",
          };

          onChange(newFilter);
        }}
        dimension={dimension}
      />
      <FormControl variant="outlined" size="small" sx={{ width: 100, minWidth: 100 }}>
        <Select value={value.exclude ? "is not" : "is"} onChange={handleDropdownChange}>
          <MenuItem value="is">is</MenuItem>
          <MenuItem value="is not">is not</MenuItem>
        </Select>
      </FormControl>
      {value && <AttributionChips filter={value} dimension={dimension} />}
    </Stack>
  );
};
