import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";

import { AutocompleteCurrency } from "../../../Components/CreateBillingProfile/BillingForm/components/AutocompleteCurrency";
import { type Contract } from "../../../types";
import { providers } from "../consts";
import { ContractsSelector } from "../ContractsSelector";

interface GeneralStepValues {
  selectedOption: string;
  planName: string;
  selectedContracts: Contract[];
  currency: string;
}

export const GeneralStep = () => {
  const { values, handleChange, setFieldValue, errors, touched } = useFormikContext<GeneralStepValues>();

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("selectedOption", event.target.value);
    setFieldValue("selectedContracts", []);
  };

  return (
    <Stack>
      <Typography variant="h4" mb={3}>
        General
      </Typography>
      <Typography variant="subtitle1" fontWeight={500}>
        Select the provider
      </Typography>

      <FormControl component="fieldset" required error={touched.selectedOption && Boolean(errors.selectedOption)}>
        <RadioGroup value={values.selectedOption} onChange={handleOptionChange}>
          {providers.map(({ name, value }, index) => (
            <FormControlLabel key={index} value={value} control={<Radio />} label={name} />
          ))}
        </RadioGroup>
        {touched.selectedOption && errors.selectedOption && <FormHelperText>{errors.selectedOption}</FormHelperText>}
      </FormControl>
      <Stack spacing={3} mt={3}>
        <TextField
          label="Plan name"
          autoFocus
          required
          variant="outlined"
          fullWidth
          name="planName"
          value={values.planName}
          onChange={handleChange}
          error={touched.planName && Boolean(errors.planName)}
          helperText={touched.planName && errors.planName}
        />
        <ContractsSelector
          selectedContracts={values.selectedContracts}
          setSelectedContracts={(contracts) => setFieldValue("selectedContracts", contracts)}
          filterType={values.selectedOption}
        />

        <AutocompleteCurrency
          value={values.currency}
          onChange={(currency) => setFieldValue("currency", currency)}
          label="Currency"
          size="small"
        />
      </Stack>
    </Stack>
  );
};
