import { Metadata } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { FixedFilters } from "../../CloudAnalytics/utilities";
import { AttributeSelector } from "../AttributeSelector";
import { type Filter } from "../types";

interface CommitmentStepValues {
  accountFilter: Filter;
  serviceFilter: Filter;
  skuFilter: Filter;
}

export const ConfigurationStep = () => {
  const { values, setFieldValue } = useFormikContext<CommitmentStepValues>();

  return (
    <Stack spacing={3} pt={3}>
      <Typography variant="h4">Configuration</Typography>
      <Stack>
        <Typography pb={0.5} variant="subtitle1" fontWeight={500}>
          Select billing accounts
        </Typography>
        <Typography variant="body2" color="text.secondary" pb={1.5}>
          Select all billing accounts which apply to this plan, by default all are selected.
        </Typography>
        <AttributeSelector
          dimensionId={`${Metadata.FIXED}:${FixedFilters.ACCOUNT}`}
          value={values.accountFilter}
          onChange={(filter) => setFieldValue("accountFilter", filter)}
        />
      </Stack>
      <Stack>
        <Typography fontWeight={500} variant="subtitle1" pb={0.5}>
          Services and SKUs
        </Typography>
        <Stack spacing={1.5}>
          <Typography variant="body2" color="text.secondary">
            Include or exclude services and SKUs from this plan.
          </Typography>
          <Stack>
            <AttributeSelector
              dimensionId={`${Metadata.FIXED}:${FixedFilters.SERVICE}`}
              value={values.serviceFilter}
              onChange={(filter) => setFieldValue("serviceFilter", filter)}
            />
            <Typography color="text.secondary" variant="caption">
              Select which services shouldn’t be included in the plan
            </Typography>
          </Stack>
          <Stack>
            <AttributeSelector
              dimensionId={`${Metadata.FIXED}:${FixedFilters.SKU}`}
              value={values.skuFilter}
              onChange={(filter) => setFieldValue("skuFilter", filter)}
            />
            <Typography color="text.secondary" variant="caption">
              Select which SKUs shouldn’t be included in the plan
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
