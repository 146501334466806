import { useMemo } from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Chip, MenuItem, TextField, Typography } from "@mui/material";

import { useContractsContext } from "../../Context/customer/ContractsContext";
import { type Contract } from "../../types";
import { assetTypeName } from "../../utils/common";
import { formatDate } from "./utils";

type Props = {
  selectedContracts: Contract[];
  setSelectedContracts: (contracts: Contract[]) => void;
  filterType?: string;
};

export const ContractsSelector = ({ selectedContracts, setSelectedContracts, filterType }: Props) => {
  const { contracts } = useContractsContext();

  const filteredContracts = useMemo(
    () => (filterType ? contracts.filter((contract) => contract.type === filterType) : contracts),
    [contracts, filterType]
  );

  const currentContract = (option) => (
    <Typography>
      {assetTypeName(option.type)} - {formatDate(option.startDate.toDate())}
      {option.endDate ? ` -> ${formatDate(option.endDate.toDate())}` : ""}
    </Typography>
  );

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={filteredContracts}
      getOptionLabel={(option) => option.type}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedContracts}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            size="small"
            label={currentContract(option)}
            sx={{ backgroundColor: "#BBDEFB", color: "#01579B" }}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={option.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selected}
          />
          {currentContract(option)}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Select contract(s)" fullWidth size="small" />
      )}
      onChange={(_, value: Contract[]) => {
        setSelectedContracts(value);
      }}
    />
  );
};
